html,
body {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  background-color: #e0e0e0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./img/background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer1 {
  margin-top: 1rem;
  background-color: #911717;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
